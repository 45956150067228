import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';
import { logIn, logOut  } from '../redux/usersSlice';
import images from "../libs/images";
import * as api from '../libs/api';
import PopupMain from '../pages/popup/PopupMain';


export default function FileDownload(props) {
    
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);

    const FileDownload = (e) => {

        if( mbData?.grade > 0){

        }else{
            if( mbData?.userSeq > 0){
                dispatch(
                    open({
                        component: <PopupMain
                            func="teacher"
                            width={400}
                            text={"선생님 인증이 필요합니다.\n\n인증 후에도 안되시면 재로그인 후 시도해 주세요."}
                            button="인증하기"
                            buttonCancel="닫기"
                            url={location.pathname}
                        />,
                        
                    })
                );
            }else{
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
            return
        }
        setLoading(true);

        api.blobData("/download" ,{
            filename:encodeURIComponent(props.filename.trimEnd()),
            folder:encodeURIComponent(props.folder)
        },mbData).then(res=>{
            /*
            if(res && res.code === 'ERR_NETWORK'){
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 만료되었습니다."
                            button="로그인"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
                
                setLoading(false);
            }
            */
            if(res && res.data){
                const ff = props.filename_original.split("/");
                let f1  = props.filename_original;
                if(ff?.length >= 1){
                    f1 = ff[ff.length - 1];
                }

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url.trim();
                link.target="_blank";
                link.setAttribute("download",`${f1}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setLoading(false);

            }
        });
    }

    
    const CategoryDownload = (e) => {
        if( mbData?.grade > 0){
            setLoading(true);
            api.blobData("/download/zip" ,{
                unitSeq:props?.unitSeq,
                subectSeq:props?.subectSeq
            },mbData).then(res=>{
                
                if(res && res.data){
    
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.target = "_blank"
                    link.setAttribute("download",`${props?.fileName.trim()}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    setLoading(false);
    
                }
            });
    
        }else{
            if(mbData?.userSeq > 0){
                dispatch(
                    open({
                        component: <PopupMain
                            func="teacher"
                            width={400}
                            text={"선생님 인증이 필요합니다.\n\n인증 후에도 안되시면 재로그인 후 시도해 주세요."}
                            button="인증하기"
                            buttonCancel="닫기"
                            url={location.pathname}
                        />,
                        
                    })
                );
            }else{
                localStorage.removeItem("access");
                localStorage.removeItem("remail");
                localStorage.removeItem("page");
                localStorage.removeItem("sendData");
                dispatch(
                    logOut()
                );
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="로그인이 필요한 서비스입니다."
                            button="로그인"
                            buttonCancel="닫기"
                            func="login"
                            url={location.pathname}
                        />
                    })
                );
            }
            return
        }  
    }
    return (
        <>  
            {props.text ? 
                (props.type == 3 ?
                    <button type="button" onClick={(e)=>FileDownload(e)} className={props.text}>{props.text}</button>
                : props.type == 4 ?
                    <button type="button" onClick={(e)=>FileDownload(e)}>{props.text}</button>
                : props.type == 5 ?
                    <span onClick={(e)=>FileDownload(e)}>{props.text}</span>           
                :
                    <button type="button" className={props?.class} onClick={(e)=>FileDownload(e)}><img src='/images/icon-download.svg' /> {props.text}</button>
                )
            :
                ( props.type == 1 ?
                    <>
                    {/*                    
                    mbData?.userSeq > 0 && mbData?.grade > 0 ?
                    <a href={api.url + "/download?filename=" + encodeURIComponent(props.filename.trim())+"&folder="+encodeURIComponent(props.folder)} className="btn_dvd" target="_blank" ><img src='/images/icon-dvd.svg' /> DVD 다운</a>
                    :
                    */}
                    <button type="button" onClick={(e)=>FileDownload(e)}><img src='/images/icon-dvd.svg' />DVD 다운</button>             
                    </>       
                : props.type == 2 ? 
                    <>
                    {/*
                    mbData?.userSeq > 0 && mbData?.grade > 0 ?
                    <a href={api.url + "/download?filename=" + encodeURIComponent(props.filename.trim())+"&folder="+encodeURIComponent(props.folder)} target="_blank" ><img src='/images/icon-download.svg' /> 교과용 자료 한 번에 다운받기</a>
                    :
                    */}
                    <button type="button" className="first" onClick={(e)=>FileDownload(e)}><img src='/images/icon-download.svg' /> 교과용 자료 한 번에 다운받기</button>   
                    </>
                : props.type == 99 ?
                    <>
                    {/*mbData?.userSeq > 0 && mbData?.grade > 0 ?
                    <a href={api.url + "/download/ziplink?unitSeq="+props?.unitSeq+"&filename="+encodeURIComponent(props?.fileName.trim())} target="_blank" ><img src='/images/button_download.svg' /></a>
                    :
                    <button type="button" onClick={(e)=>CategoryDownload(e)}><img src='/images/button_download.svg' /></button>
                   */}
                    </>
                
                :
                    <button type="button" onClick={(e)=>FileDownload(e)}><img src='/images/button_download.svg' /></button>
                )            
                
            }
            {loading ? <div className={"file-down-box"+(loading ? ' active' : '')}><img src="/images/loading.svg" /></div> : <></> }
        </>
        

    );
}
