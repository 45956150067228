/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import routes from '../libs/routes';
import images from "../libs/images";
import { useCookies } from 'react-cookie';
import { logIn, logOut,setData as setUserData   } from '../redux/usersSlice';
import PopupMain from '../pages/popup/PopupMain';
import { open, close } from '../redux/popupSlice';
import { getData as getEtcData } from '../redux/etcSlice';
import { setData as setEtcData } from '../redux/etcSlice';
import * as api from '../libs/api';
import { imageUrl } from "../libs/utils";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, FreeMode } from 'swiper/modules';

import NaverLogin from './naver_login';
import KakaoLogin from './kakao_login';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';

export default function(props) {
    
    const params = useParams();
    const [login, setLogin] = useState({id:"",pw:""}); 
    
    const [list, setList] = useState([]);
    const [list2, setList2] = useState([]);
    const [isRemember, setIsRemember] = useState(false);
    const [view_quick, setViewQuick] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId"]);


    const [loginTest, setLoginTest] = useState(0);

    const LoginBtn = () => {

        if(login.id === "" || login.pw === ""){
     
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="아이디 / 비밀번호를 확인해주세요."
                        button="닫기"
                    />,
                    
                })
            );
        }
    
        api.postData("/signin", {
            account:login.id,
            pw:login.pw
        }).then(res=>{
       
            if(res && res?.data?.status=="SUCCESS"){
                dispatch(
                    logIn({
                        mbData: res.data.data
                    })
                );
                localStorage.setItem("access", res.data.data.accessToken);
                api.getDataRefresh("/book", {
                    memberSeq:res.data.data.userSeq
                },res.data.data).then(res2=>{
                    if(res2 && res2?.data?.status=="SUCCESS"){
                        //props?.setQuickMyBook(res2.data.data);  
                        dispatch(
                            setUserData({
                                key: 'mbBook',
                                value: res2.data.data
                            })
                        )             
                    }
                });

                if(isRemember){
                    setCookie("rememberUserId", login.id);
                }else {
                    removeCookie("rememberUserId");
                }
                if(location?.state?.url){
                    navigate(location.state.url);
                }else{
                    navigate("/");
                }
            }else{
                dispatch(
                    open({
                        component: <PopupMain
                            width={400}
                            text="아이디 / 비밀번호를 확인해주세요."
                            button="닫기"
                        />,
                        
                    })
                );
            }
     
        });
    }

    const LogoutBtn = () => {   
        localStorage.removeItem("access");
        localStorage.removeItem("remail");
        localStorage.removeItem("page");
        localStorage.removeItem("sendData");
        dispatch(
            logOut()
        );
        navigate("/");
    };

    const [isMyclassHover, setIsMyclassHover] = useState(false);
    const [isMybookHover, setIsMybookHover] = useState(false);
    const [isClassbookHover, setIsClassbookHover] = useState(false);
    const [isUpHover, setIsUpHover] = useState(false);
    const [isChatHover, setIsChatHover] = useState(false);

    const isMyclassHoverOn = () => {
        setIsMyclassHover(true);
    }

    const isMyclassHoverOff = () => {
        setIsMyclassHover(false);
    }

    const isMybookHoverOn = () => {
        setIsMybookHover(true);
    }

    const isMybookHoverOff = () => {
        setIsMybookHover(false);
    }

    const isClassbookHoverOn = () => {
        setIsClassbookHover(true);
    }

    const isClassbookHoverOff = () => {
        setIsClassbookHover(false);
    }

    const isUpHoverOn = () => {
        setIsUpHover(true);
    }

    const isUpHoverOff = () => {
        setIsUpHover(false);
    }

    const isChatHoverOn = () => {
        setIsChatHover(true);
    }

    const isChatHoverOff = () => {
        setIsChatHover(false);
    }

    

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { topBanner } = useSelector(s => s.etcReducer);
    const { mbData, accessToken, autoLogin, mbBook } = useSelector(s => s.usersReducer);
    function goUrl(url) {
        navigate(url);
    }

    const excludedRoutes = [routes.login, routes.register01, routes.register02];

    const handleToggleBars = () => {
        const quickBar1 = document.querySelector('.quick_bar_1');
        const quickBar2 = document.querySelector('.quick_bar_2');
        const quickArrow = document.querySelector('.quick_arrow');
    
        if (quickBar1.style.display !== 'none') {
            quickBar1.style.display = 'none';
            quickBar2.style.display = 'flex';
            quickArrow.classList.add('_2');
        } else {
            quickBar1.style.display = 'flex';
            quickBar2.style.display = 'none';
            quickArrow.classList.remove('_2');
        }
    };
    
    const onEmailChange = (e) => {

        setLogin({...login, id:e.target.value})
    }
    const onPwChange = (e) => {

        setLogin({...login, pw:e.target.value});
    }

    
    const grade = ['일반','선생님','편집자','영업','','','','','관리자','최고관리자'];
    const onScrollTop = () =>{
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        api.getData("/top", {
        }).then(res=>{
            if(res?.headers?.get("New-Access-Token")){
                localStorage.setItem("access", res.headers.get("New-Access-Token"));
            }
            dispatch(
                setEtcData({
                    key: 'topBanner',
                    value: ""
                })
            )
            if(res && res?.data?.status=="SUCCESS"){
                if(res?.data?.data?.pc) {
                    dispatch(
                        getEtcData({
                            pc:imageUrl + "banner?file="+res.data.data?.pc,
                            mobile:imageUrl + "banner?file="+ res.data.data?.mobile,
                            url: res.data.data?.url
                        })
                    )
                    dispatch(
                        setEtcData({
                            key: 'topBanner',
                            value: 1
                        })
                    )                    
                }
            }
        });
    }, [location.pathname]);
    useEffect(() => {
       
        if (cookies.rememberUserId !== undefined) {
            setLogin({ ...login, id: cookies.rememberUserId });
            setIsRemember(true);
        }
        
        if(mbData?.userSeq && mbData?.userSeq > 0 ){
            if( props.quick_mybook.length <= 0  ){
                api.getDataRefresh("/book", {
                    memberSeq:mbData.userSeq
                },mbData).then(res=>{
              
                    if(res && res?.data?.status=="SUCCESS"){
                        //props?.setQuickMyBook(res.data.data);
                        dispatch(
                            setUserData({
                                key: 'mbBook',
                                value: res.data.data
                            })
                        )
                    }
                });

            }
            /*

            */
            /*
            if(quick_mybook?.length > 0){
                setList(quick_mybook);
            } else {
                api.getDataRefresh("/book", {
                    memberSeq:mbData.userSeq
                },mbData).then(res=>{
                    if(res && res?.data?.status=="SUCCESS"){
                        setList(res.data.data);               
                    }
                });
            }
            */
        }
        
    }, []);
    
    
    
    useEffect(() => {
        /*
        const aa = (localStorage.getItem("mybook"));
        const bb = aa?.split(",");
        setViewQuick(bb);
        */

     
        const childMessage = (e) => {

            if (e.data.type !== 'kakao' && e.data.type !== 'naver') {
              return;
            }
            let sns = e.data.payload;
            if(e.data.type === 'kakao'){
                sns['snsType'] = 'kakao';
            }
            if(e.data.type === 'naver'){
                sns['snsType'] = 'naver';
            }
            api.postData("/signin/sns", {
                account:sns.id,
                pw:sns['snsType']
            }).then(res=>{
                if(res && res?.data?.status=="SUCCESS"){
                    dispatch(
                        logIn({
                            mbData: res.data.data
                        })
                    );
                    localStorage.setItem("access", res.data.data.accessToken);
                    api.getDataRefresh("/book", {
                        memberSeq:res.data.data.userSeq
                    },res.data.data).then(res2=>{
                        if(res2 && res2?.data?.status=="SUCCESS"){
                            //props?.setQuickMyBook(res2.data.data);    
                            dispatch(
                                setUserData({
                                    key: 'mbBook',
                                    value: res2.data.data
                                })
                            )           
                        }
                    });
                    if(isRemember){
                        setCookie("rememberUserId", login.id);
                    }else {
                        removeCookie("rememberUserId");
                    }
                    if(location?.state?.url){
                        navigate(location.state.url);
                    }else{
                        navigate("/");
                    }
                }else{
                    navigate(routes.register01,{state:sns})
                }
            });            
        }
        window.addEventListener('message', childMessage);
        return () => {
            window.removeEventListener('message', childMessage);
        };
    },[]);
    const onQuickBook = (val) =>{
        let cur = 0;
        if(val.subject === "사회"){
            cur = 1;
        }else if(val.subject === "미술"){
            cur = 2;
        }
        navigate("/curriculum/"+cur+"/"+val.subjectSeq);
    }
    return (
        <div className="quick_menu_box">
            <div className="quick_bar_1">
                { mbData?.email ?                
                    <div className="quick_login_btn login" onClick={LogoutBtn}>
                        <img src='/images/quick-logout.svg' />
                        로그아웃
                    </div>
                :
                    <div className="quick_login_btn login" onClick={handleToggleBars}>
                        <img src='/images/quick-login.svg' />
                        로그인
                    </div>
                }

                <div className="quick_login_btn sub"
                    onClick={()=>goUrl('/my/book')}
                    onMouseEnter={isMyclassHoverOn}
                    onMouseLeave={isMyclassHoverOff}
                >
                    <img src={isMyclassHover ? images.quick_myclass_white : images.quick_myclass} />
                    나의 교실
                </div>

                <div className="quick_login_btn sub"
                    onClick={()=>goUrl('/my/book')}
                    onMouseEnter={isMybookHoverOn}
                    onMouseLeave={isMybookHoverOff}
                >
                    <img src={isMybookHover ? images.quick_mybook_white : images.quick_mybook} />
                    내 교과서
                </div>
                {/*
                <div className="quick_login_btn sub"
                    onClick={()=>window.alert("교과서 자료실은 어디?")}
                    onMouseEnter={isClassbookHoverOn}
                    onMouseLeave={isClassbookHoverOff}
                >
                    <img src={isClassbookHover ? images.quick_classbook_white : images.quick_classbook} />
                    교과서<br />자료실
                </div>

                <div className="quick_login_btn sub"
                    onClick={()=>window.alert("추천자료는 어디?")}
                    onMouseEnter={isUpHoverOn}
                    onMouseLeave={isUpHoverOff}
                >
                    <img src={isUpHover ? images.quick_up_white : images.quick_up} />
                    추천자료
                </div>
            */}
                <div className="quick_login_btn sub"
                    onClick={()=>goUrl('/my/qna')}
                    onMouseEnter={isChatHoverOn}
                    onMouseLeave={isChatHoverOff}
                >
                    <img src={isChatHover ? images.quick_chat_white : images.quick_chat} />
                    내 문의함
                </div>
                <div className="quick_arrow" onClick={handleToggleBars}></div>
            </div>

            <div className="quick_bar_2" style={{display: 'none'}}>
                <div className="quick_logo_box">
                    <img src='/images/main-logo.svg' />
                </div>
                { mbData?.email ? 
                    <div className="quick_login_test">
                        <div className="quick_login_teacher">
                            <p className="name">{mbData.name} {grade[mbData.grade]}</p>
                            <p className="school">{mbData.school}</p>
                            <button type="button" onClick={LogoutBtn}>로그아웃</button>
                            <p className="my_set" onClick={()=>goUrl('/my/info')}>
                                회원정보 수정
                                <img src='/images/service-btn-right.svg'/>
                            </p>
                        </div>
                        <button type="button" className="quick_myclass_btn" onClick={()=>goUrl('/my/book')}>
                            나의 교실
                            <img src='/images/arrow-right-white.svg'/>
                        </button>

                        <div className="quick_mybook_box">
                            <p className="title">내 교과서

                            </p>
                            <div className="quick_swiper_box">

                                <Swiper className="quick_swiper1"  style={{ maxWidth: '100px', width: '100%', height: '120px'}}
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    loop={true}
                                    navigation={{
                                        nextEl: '.quick_swiper1_next',
                                        prevEl: '.quick_swiper1_prev',
                                    }}
                                    >
                                        {mbBook?.length > 0
                                        ? mbBook?.map(val=>(
                                            <SwiperSlide><img src={imageUrl + "subject?file="+val.subjectThumbnail} width="100" height="120"  onClick={()=>onQuickBook(val)} /></SwiperSlide>
                                        ))
                                        : 
                                        props?.local_mybook?.map(val=>
                                            val !== "" &&
                                            <SwiperSlide><img src={imageUrl + "subject?file="+val.img} width="100" height="120"  onClick={()=>navigate(val.url)}/></SwiperSlide>
                                        )
                                        }
                                </Swiper>
                                <div className={"quick_swiper1_next" + (mbBook?.length > 1 || props?.local_mybook?.length > 1 ? '' : ' hide')}><img src='/images/update-right.svg' /></div>
                                <div className={"quick_swiper1_prev" + (mbBook?.length > 1 || props?.local_mybook?.length > 1 ? '' : ' hide')}><img src='/images/update-left.svg' /></div>
                            </div>

                            <p className={"sub_title"+(mbBook?.length <=0 ? (props?.local_mybook?.length <= 0 ? ' center' : '') : '')}>내 교과서를 설정해 주세요</p>
                        </div>
                    </div>
                :
                    <div className="quick_login_test">

                        <div className="quick_login_input">
                            <div className="quick_login_input_box">
                                <input type="text" placeholder="아이디 입력" className="quick_id_input"  value={login.id} onChange={(e)=>onEmailChange(e)}/>
                                <input type="password" placeholder="비밀번호 입력" className="quick_pw_input" onChange={(e)=>onPwChange(e)}/>
                            </div>
                            <div className="checkbox">
                                
                                <label ><input type="checkbox" id="id_save" className="input_chk" checked={isRemember} onClick={()=>setIsRemember(!isRemember)}/> 아이디 저장</label>
                            </div>
                            <button type="button" className="login_btn" onClick={()=>LoginBtn()}>로그인</button>
                            <div className="login_help_box" style={{marginBottom: '12px'}}>
                                <p onClick={()=>goUrl('/find/id')}>아이디 찾기</p>
                                <span style={{color: '#ccc', margin: '0 8px'}}>|</span>
                                <p onClick={()=>goUrl('/find/pw')}>비밀번호 찾기</p>
                            </div>
                            <div className="sns_login_box" style={{margin: '0'}}>
                                <KakaoLogin />
                                <NaverLogin type="3" />
                            </div>
                        </div>
                    </div>
                }

                        <div className="quick_link_box">  {/*
                            <div className="tap" onClick={()=>window.alert("교과서 자료실은 어디?")} style={{borderBottom: '1px solid #ddd'}}>
                                교과서 자료실
                            </div>
                            
                          
                            <div className="tap" onClick={()=>goUrl('/update')} style={{borderBottom: '1px solid #ddd'}}>
                                추천자료
                            </div>*/}

                            <div className="tap" onClick={()=>goUrl('/my/qna')}>
                                
                                내 문의함
                            </div>
                        </div>
                

                <div className="quick_arrow _2" onClick={handleToggleBars}></div>
            </div>
            
        </div>
        
    )
}

