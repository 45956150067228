import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logIn, logOut  } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
import images from "../../libs/images";
import PopupMain from '../popup/PopupMain';
import * as api from '../../libs/api';
import { imageUrl } from "../../libs/utils";
import BtnFileDownload from '../../components/file_download';
import axios, { AxiosRequestConfig } from "axios";


export default function Qna() {
    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const location = useLocation(); 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);

    
    useEffect(() => {
        if(!mbData.email){
            localStorage.removeItem("access");
            localStorage.removeItem("remail");
            localStorage.removeItem("page");
            localStorage.removeItem("sendData");
            dispatch(
                logOut()
            );
            dispatch(
                open({
                    component: <PopupMain
                        width={400}
                        text="로그인이 필요한 서비스입니다."
                        button="로그인"
                        buttonCancel="닫기"
                        func="login"
                        url={location.pathname}
                    />,
                })
            );
            navigate("/login");
        }else{
            onList(1);
        }
    },[]);

    const onOpen = (val) => {
        setList(
            list.map(par=>par.qnaSeq === val.qnaSeq ? {...par, open:!val.open} : par)
        )
    }
  
    const onList = async () =>{

   
        api.getDataRefresh("/qna", {
            memberSeq:mbData.userSeq
        },mbData).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                setList(res.data.data);               
            }
        });
    }

    return (
        <div className="changbi">
            <div className="wrap">
            <div className="my faq">
                    <h1 className="title none">내 문의함<button type="button" onClick={()=>navigate("/my/qna/write")}>1:1 문의하기</button></h1>
                    
                    <div className="mscroll">
                        <dl>
                            <dt>
                                <label className="w120">분류</label>
                                <label className="wall">제목</label>
                                <label className="w100">문의일자</label>
                                <label className="w100">답변상태</label>
                            </dt>
                            {list && list?.map((val) => (
                            <dd className={val.open ? 'active' : ''}>
                                <div  onClick={(e)=>onOpen(val)} >
                                    <span className="w120">{val.type}</span>
                                    <p className="wall">{val.title}</p>
                                    <span className="w100">{val.registDate?.substr(0,10).replaceAll("-",".")}</span>
                                    <span className="w100 ing">{val.answerStatus ? '완료' : '미답변'}</span>
                                </div>
                                <div className="write">
                                    <p>
                                    {val.qnaMemo}
                                    </p>
                                    {val?.file1 && <BtnFileDownload folder="board" type="4" text={val?.oriFile1} filename={val?.file1} filename_original={val?.oriFile1} />}
                                    {val?.file2 && <BtnFileDownload folder="board" type="4" text={val?.oriFile2} filename={val?.file2} filename_original={val?.oriFile2} />}
                                    {val?.file3 && <BtnFileDownload folder="board" type="4" text={val?.oriFile3} filename={val?.file3} filename_original={val?.oriFile3} />}
                                </div>
                                <div className="reply">
                                {val.answerStatus ?
                                    <>
                                    <span>{val.answerDate?.substr(0,10).replaceAll("-",".")}</span>
                                    <p>
                                    {val.answer}
                                    </p>
                                    {val?.answerFile1 && <BtnFileDownload folder="board" type="4" text={val?.answerOriFile1} filename={val?.answerFile1} filename_original={val?.answerOriFile1} />}
                                    {val?.answerFile2 && <BtnFileDownload folder="board" type="4" text={val?.answerOriFile2} filename={val?.answerFile2} filename_original={val?.answerOriFile2} />}
                                    {val?.answerFile3 && <BtnFileDownload folder="board" type="4" text={val?.answerOriFile3} filename={val?.answerFile3} filename_original={val?.answerOriFile3} />}
                                    </>
                                    :
                                    <p>답변 대기중입니다.</p>
                                }
                                </div>
                            </dd>
                            ))}
                        </dl>
                        {list.length <= 0 && <p className="no-data">문의 내역이 없습니다.</p>}
                    </div>  
                </div>
                
            </div>
        </div>
    );
}
