import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import Popup from './redux-components/Popup';
import Loading from './redux-components/Loading';

import Layout from './Layout';
import * as api from './libs/api';

import './App.css';
import './css/layout.css';
import './css/common.css';
import './css/media.css';
import './css/changbi.css';
import './css/suneditor.css';


function App() {

  /* 빌드시 주석해제 */
  /* console = {};
  console.log = function(){};
  console.warn = function(){};
  console.error = function(){}; */
  useEffect(() => {
    // 브라우저가 닫힐 때 로그아웃

      const handleBeforeUnload = (event) => { 
        if(localStorage.getItem("folder_url")!=""){
          api.getData("/teacher/ebook/check" ,{link:localStorage.getItem("folder_url")}).then(res=>{});
          // 기본 동작 방지 (필요에 따라 사용)
          //event.preventDefault();
          //event.returnValue = ''; 
        }
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      
   
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  })

  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
              <Layout />
              <Popup />
              <Loading />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
