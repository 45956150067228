import axios from 'axios';
export const url = process.env.REACT_APP_SERVER_URL;


export const blobData = async (link,values,token) => {
  try {
    return await axios.get(url + link,{
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token.refreshToken,
        'Authorization': 'Bearer '+localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      },
      responseType: 'blob'
    });
  } catch (e) {
    return e;
  }
}
export const getDataRefresh = async (link,values,token) => {
  try {
    return await axios.get(url + link,{
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token.refreshToken,
        'Authorization': 'Bearer '+ localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  } catch (e) {
    return e;
  }
}

/* 멀티파일업로드 */
export const fileUploadRefresh = async (link,formData,token) => {
  try {
    return await axios.post(url + link,
      formData,
      {
        headers: {
          'accept': 'application/json;charset=UTF-8',
          'Refresh-Token': token.refreshToken,
          'Authorization': 'Bearer '+localStorage.getItem("access"),
          'content-type': 'multipart/form-data'
        }
      }
    );
  } catch (e) {
    return e;
  }
}
export const postDataRefresh = async (link,values,token) => {
  try {
    return await axios.post(url + link,
      values,
      {
        headers: {
          'accept': 'application/json;charset=UTF-8',
          'Refresh-Token': token.refreshToken,
          'Authorization': 'Bearer '+localStorage.getItem("access"),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
  } catch (e) {
    return e;
  }
}

export const putDataRefresh = async (link,values,params,token) => {
  try {
    return await axios.put(url + link,
      values,
      {
        params:params,
        headers: {
          'accept': 'application/json;charset=UTF-8',
          'Refresh-Token': token.refreshToken,
          'Authorization': 'Bearer '+localStorage.getItem("access"),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
  } catch (e) {
    return e;
  }
}

export const deleteDataRefresh = async (link,values,token) => {
  try {
    return await axios.delete(url + link,
    {
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token.refreshToken,
        'Authorization': 'Bearer '+localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  } catch (e) {
    return e;
  }
}


export const getData = async (link,values,token) => {
  try {
    return await axios.get(url + link,{params: values});
  } catch (e) {
    
    return e;
  }
}

export const postData = async (link,values,token) => {
  try {
    return await axios.post(url + link,values);
  } catch (e) {
    return e;
  }
}
