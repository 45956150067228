import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import { useNavigate } from 'react-router';

import { open, close } from './redux/popupSlice';
import { logIn, logOut  } from './redux/usersSlice';

import routes from "./libs/routes";
import PopupMain from './pages/popup/PopupMain';
/* import axios from "axios";
import { createBrowserHistory } from "history";

import { BrowserView, MobileView } from 'react-device-detect';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import * as APIS from "./utils/service"; */

/* ====== redux ====== */
/* import { logIn, logOut, configData } from './redux/usersSlice'; */
/* =================== */

/* import MobileDownView from './MobileDownView';
import Test from "./pages/test/Test"; */

import Quick_menu from "./components/quick_menu";
import Header from "./components/header";
import Footer from "./components/footer";

import Main from "./pages/main";
import Login from "./pages/login";
import Register01 from "./pages/register/register01";
import Register02 from "./pages/register/register02";
import Auth from "./pages/register/auth";
import Check from "./pages/register/check";
import Find from "./pages/find";
import Modal from "./components/modal";
import NotFound from "./pages/nopage";
import Curriculum from "./pages/curriculum";
import Innovation from "./pages/innovation/innovation";
import Calendar from "./pages/innovation/calendar";
import Month from "./pages/innovation/month";
import MonthInfo from "./pages/innovation/month_info";
import Dictionary from "./pages/innovation/dictionary";
import DictionaryMain from "./pages/innovation/main";


import Book from "./pages/book";
import Teacher from "./pages/teacher";
import Creative from "./pages/creative";

import Notice from "./pages/customer/notice";
import Faq from "./pages/customer/faq";
import Event from "./pages/customer/event";
import EventInfo from "./pages/customer/event_info";
import MyBook from "./pages/my/book";
import MyInfo from "./pages/my/info";
import MyQna from "./pages/my/qna";
import MyQnaForm from "./pages/my/qna_form";
import MyScrap from "./pages/my/scrap";
import MyLeave from "./pages/my/leave";
import Update from "./pages/update";
import Test from "./pages/test";
import SnsNaver from "./components/sns_naver";
import SnsKakao from "./components/sns_kakao";
import Metas from "./components/meta";

import { loadingEnd } from "./redux/loadingSlice"; 

import * as api from './libs/api';

function Layout() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { mbData, accessToken, autoLogin } = useSelector(s => s.usersReducer);
    const { topBanner } = useSelector(s => s.etcReducer);
    const [menu, setMenu] = useState();
    const [menu_year, setMenuYear] = useState();
    const [curr_menu, setCurrMenu] = useState();
    const [quick_mybook, setQuickMyBook] = useState([]);
    const [local_mybook, setLocalMyBook] = useState([]);
    const [meta_title, setMetaTitle] = useState("(주)창비교육");

    const [isServer, setServer] = useState(false);

    useEffect(() => {    
        
        setLocalMyBook([]); 
        let arr = [];
        let arr2 = [];
        api.getData("/menu", {}).then(res=>{         
            if(res && res?.data?.status=="SUCCESS"){
                
                res.data.data.year.map(val=>{
                    if(!arr2[val.subject]){
                        arr2[val.subject] = [];
                    }                     
                    if(!arr2[val.subject][val.school]){
                        arr2[val.subject][val.school] = [];
                    } 
                 
                    arr2[val.subject][val.school].push(val)                                
                })

                res.data.data.menu.map(val=>{
                    if(!arr[val.subject]){
                        arr[val.subject] = [];
                    }                     
                    if(!arr[val.subject][val.school]){
                        arr[val.subject][val.school] = [];
                    } 
                    if(!arr[val.subject][val.school][val.year]){
                        arr[val.subject][val.school][val.year] = [];
                    }    
                    arr[val.subject][val.school][val.year].push(val)                                
                })
                setMenu(arr);
                setMenuYear(arr2);
            }else{
                setServer(true);
            }
        });
        //내 교과서 퀵메뉴 연동
        
    },[])

    useEffect(() => {
        if(mbData?.userSeq > 0){
            api.postDataRefresh("/token" ,{},mbData).then(res=>{ 
                
                if(res && (res.code === 'ERR_NETWORK' || res.code === 'ERR_BAD_REQUEST')){
                    localStorage.removeItem("access");
                    localStorage.removeItem("remail");
                    localStorage.removeItem("page");
                    localStorage.removeItem("sendData");
                    dispatch(
                        logOut()
                    );
                    dispatch(
                        open({
                            component: <PopupMain
                                width={400}
                                text={"로그인이 만료되었습니다.\n\n재로그인 해주세요."}
                                button="로그인"
                                func="login"
                                url={location.pathname}
                            />
                        })
                    );
                }               
                if(res?.headers?.get("New-Access-Token")){
                    localStorage.setItem("access", res.headers.get("New-Access-Token"));
                }
                            
                if(res && res?.data?.status=="SUCCESS"){
                    
                }
            })
        }

    }, [location.pathname]); 
    

    return (
        <>  
            {isServer ?
                <div><br/><br/>서버 점검중입니다. 잠시후 이용해주세요.</div>
            :
            <>
            <Metas />
            <Quick_menu quick_mybook={quick_mybook} setQuickMyBook={setQuickMyBook} local_mybook={local_mybook} setLocalMyBook={setLocalMyBook}/>
            <Header menu={menu} menu_year={menu_year} curr_menu={curr_menu}/>
                <div className={`default_box ${!topBanner ? 'banner' : ''}`}>
                    <Routes>
                        {
                            <>
                                <Route exact path="/sns/naver" element={<SnsNaver />} />
                                <Route exact path="/sns/naver/:id" element={<SnsNaver />} />
                                <Route exact path="/sns/kakao" element={<SnsKakao />} />
                                <Route exact path="/sns/kakao/:id" element={<SnsKakao />} />
                                <Route exact path={routes.main} element={<Main />} />
                                <Route exact path="/auth" element={<Auth />} />
                                <Route exact path={routes.login} element={<Login quick_mybook={quick_mybook} setQuickMyBook={setQuickMyBook}  />} />
                                <Route exact path={routes.register01} element={<Register01 />} />
                                <Route exact path={routes.register02} element={<Register02 />} />
                                <Route exact path="/check" element={<Check />} />                                
                                <Route exact path={routes.modal} element={<Modal />} />
                                {/*
                                <Route exact path={routes.month} element={<Month />} />
                                <Route exact path={routes.month} element={<Month />} />
                                <Route exact path={routes.dictionary} element={<Dictionary />} />
                                */}
                                {/*아이디,비번 찾기*/}                                
                                <Route exact path={routes.find} element={<Find />} />
                                <Route exact path={routes.find+"/id"} element={<Find />} />
                                <Route exact path={routes.find+"/pw"} element={<Find />} />
                                {/*국어 사회 미술*/}
                                <Route exact path="/curriculum" element={<Curriculum />}  />                        
                                <Route exact path="/curriculum/:type/:id" element={<Curriculum setCurrMenu={setCurrMenu} quick_mybook={quick_mybook} setQuickMyBook={setQuickMyBook}  local_mybook={local_mybook} setLocalMyBook={setLocalMyBook} />} />                          
                                {/*
                                <Route exact path="/curriculum/native" element={<Curriculum />} />
                                <Route exact path="/curriculum/social" element={<Curriculum />} />
                                <Route exact path="/curriculum/art" element={<Curriculum />} />
                                */}
                                {/* 수업 혁신 */}
                                <Route exact path={routes.innovation} element={<Innovation />} />
                                <Route exact path={routes.calendar} element={<Calendar />} />
                                <Route exact path="/calendar/:type/:id" element={<MonthInfo />} />
                                <Route exact path="/month/:type" element={<Month />} />
                                <Route exact path="/month/:type/:id" element={<MonthInfo />} />
                                <Route exact path="/preview/calendar/:type/:id" element={<MonthInfo name="preview" />} />

                                
                                <Route exact path="/dictionary" element={<DictionaryMain />} />
                                <Route exact path="/dictionary/0" element={<Dictionary />} />
                                <Route exact path="/dictionary/1" element={<Dictionary />} />
                                <Route exact path="/dictionary/2" element={<Dictionary />} />
                                <Route exact path="/dictionary/0/:id" element={<Dictionary />} />
                                <Route exact path="/dictionary/1/:id" element={<Dictionary />} />
                                <Route exact path="/dictionary/2/:id" element={<Dictionary />} />
                                {/* 수업연계도서 메인배너 */}
                                <Route exact path="/creative" element={<Creative />} />                                
                                <Route exact path="/book" element={<Book />} />                     
                                <Route exact path="/teacher" element={<Teacher />} />
                                {/* 수업연게도서 카테고리별 리스트 */}
                                <Route exact path="/creative/list/:category_seq" element={<Creative />} />
                                <Route exact path="/book/list/:category_seq" element={<Book />} />
                                <Route exact path="/teacher/list/:category_seq" element={<Teacher />} />
                                {/* 수업연게도서 카테고리별 상세 */}
                                <Route exact path="/creative/view/:category_seq/:board_seq" element={<Creative />} />
                                <Route exact path="/book/view/:category_seq/:board_seq" element={<Book />} />
                                <Route exact path="/teacher/view/:category_seq/:board_seq" element={<Teacher />} />
                                <Route exact path="/preview/creative/view/:category_seq/:board_seq" element={<Creative name="preview" />} />
                                <Route exact path="/preview/book/view/:category_seq/:board_seq" element={<Book name="preview" />} />
                                <Route exact path="/preview/teacher/view/:category_seq/:board_seq" element={<Teacher name="preview" />} />
                                {/* 고객센터 */}
                                <Route exact path="/notice" element={<Notice />} />
                                <Route exact path="/notice/:id" element={<Notice />} />
                                <Route exact path="/faq" element={<Faq />} />
                                <Route exact path="/event" element={<Event />} />
                                <Route exact path="/event/:id" element={<EventInfo />} />
                                <Route exact path="/my/book" element={<MyBook quick_mybook={quick_mybook} setQuickMyBook={setQuickMyBook} />} />
                                <Route exact path="/my/info" element={<MyInfo />} />
                                <Route exact path="/my/qna" element={<MyQna />} />
                                <Route exact path="/my/qna/write" element={<MyQnaForm />} />
                                <Route exact path="/my/scrap" element={<MyScrap />} />
                                <Route exact path="/my/leave" element={<MyLeave />} />
                                
                                <Route exact path="/update" element={<Update />} />
                                <Route exact path="/update/:id" element={<Update />} />
                                <Route exact path="/test" element={<Test />} />

                                <Route path="*" element={<NotFound />}></Route>    
                            </>
                        }
                        
                    </Routes>
                </div>

            <Footer />
            </>
            }
        </>
        )
    
}

export default Layout;